/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMemo, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { getNames } from 'country-list';
import { ContactButton, Layout, SEO } from '@/components';
import useMounted from '@/hooks/useMounted';

export const Head = ({ location }) => {
  const title = 'Contact Us';
  const description = 'Enhance your operations and bring your community together with Njano for FREE';
  return <SEO title={title} description={description} location={location} />;
};

export default () => {
  const [country, setCountry] = useState(null);
  const hasMounted = useMounted();
  const countries = useMemo(
    () =>
      getNames().map((name) => ({
        value: name,
        label: name
      })),
    []
  );
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const res = await fetch('/.netlify/functions/email-submission', {
        method: 'POST',
        body: JSON.stringify({
          name: e.target.name.value,
          email: e.target.email.value,
          content: e.target.message.value,
          country: country?.value,
          type: 'contact'
        })
      });
      const json = await res.json();
      if (json.success === false && json.message) {
        toast.error(json.message, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
      if (json.success === true) {
        toast('We will get back to you soon! Thank you 🎉 ', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        e.target.reset();
        setCountry(null);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Layout dark logo>
      <div className="flex items-center justify-center">
        <div className="bg-darkGrey sm:my-8 sm:w-full sm:max-w-lg sm:align-middle xl:max-w-xl relative inline-block overflow-hidden text-left text-white align-bottom transition-all rounded-lg">
          <div className="bg-darkGray px-4 py-6">
            <div className="sm:flex sm:items-center">
              <div className="w-full mt-3 text-center">
                <h6 className="mt-16 text-center font-heading text-[2.125rem] font-bold">Contact Us</h6>
                <ContactButton />
                <form onSubmit={handleSubmit} className="flex flex-col items-center justify-center my-8 space-y-8">
                  <input
                    type="email"
                    placeholder="Your email"
                    name="email"
                    required
                    className="placeholder:text-white rounded-3xl font-kanit font-extralight w-full p-4 text-lg text-white bg-transparent border border-white outline-none"
                  />
                  <input
                    type="text"
                    name="name"
                    required
                    placeholder="Your space name"
                    className="placeholder:text-white rounded-3xl font-kanit font-extralight w-full p-4 text-lg text-white bg-transparent border border-white outline-none"
                  />
                  {hasMounted ? (
                    <Select
                      id="country-select"
                      instanceId="country-select"
                      onChange={setCountry}
                      value={country}
                      name="country"
                      classNames={{
                        control: () =>
                          'w-full p-4 !bg-transparent !rounded-3xl border border-white !focus:border-secondary !text-white focus:border-secondary hover:border-secondary',
                        input: () => '!text-white font-kanit font-extralight text-lg text-left',
                        placeholder: () =>
                          '!text-white font-kanit font-extralight text-lg text-left !placeholder:text-white',
                        singleValue: () => '!text-white font-kanit font-extralight text-lg text-left',
                        menu: () => '!bg-darkGray',
                        option: ({ isFocused, isSelected }) =>
                          clsx(
                            isFocused && '!bg-secondary !text-darkGray',
                            isSelected && '!bg-secondary !text-darkGray',
                            '!bg-darkGray !text-white !font-kanit !font-extralight !text-lg text-left'
                          )
                      }}
                      required
                      options={countries}
                      placeholder="Your country"
                      className="placeholder:text-white w-full text-white bg-transparent outline-none"
                    />
                  ) : null}
                  <textarea
                    cols={30}
                    rows={4}
                    type="text"
                    name="message"
                    required
                    placeholder="Your message"
                    className="rounded-3xl font-kanit font-extralight placeholder:text-white w-full p-4 text-lg text-white bg-transparent border border-white outline-none"
                  />
                  <button
                    type="submit"
                    className="h-[3.875rem]
                        w-[13.75rem] rounded-3xl bg-secondary font-heading text-2xl capitalize leading-10 text-[#050505] hover:opacity-90"
                  >
                    Send
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
